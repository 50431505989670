import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
// import Seo from "../components/seo"

import ItineraryHeader from "../components/itinerary-header"
import TwoColumnAlt from "../components/two-column-alt"
import Button from "../components/button"
import Seo from "../components/seo"

const fragment = (postType) => {
  console.log(postType + " is the type");
}

export const query = graphql`
  query($id: String) {
    wpItinerary(id: { eq: $id }) {
      id
      title
      content
      slug
      seo {
        metaDesc
        readingTime
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        title
        twitterTitle
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        opengraphType
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      acfItinerary {
        destinationDescription
        map
        heroImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(aspectRatio: 1.7, layout: FULL_WIDTH)
            }
          }
        }
        destinations {
          description
          name
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

const WpItinerary = ({  data: {
    wpItinerary: { title, acfItinerary, id, slug, seo },
  }}) => {
  const cta = {'url': '/book', 'target': ''}
  return (
    <Layout>
      {seo && (
        <Seo
          title={seo.title || title}
          description={seo.metaDesc}
          seo={seo}
        />
      )}
      {/* <h2 className="text-center">Sailing soon!</h2> */}
      
      <ItineraryHeader
        title={title}
        shortDescription={acfItinerary.destinationDescription}
        destinations={acfItinerary.destinations}
        image={acfItinerary.heroImage}
        slug={slug}
      />
      <TwoColumnAlt content={acfItinerary.destinations} />
      <section className="flex justify-center pb-[6vw]">
        <Button classes="btn bg-yellow btn-xl text-dark-blue" cta={cta}>Book Now</Button>
      </section>
     
    </Layout>
  )
}

export default WpItinerary
