import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import "./two-column.css"

export const TheImage = (props) => {
  const image = getImage(props.image.localFile)
  return (<GatsbyImage image={image} alt={'Gnar'} />)
}

const TwoColumnAlt = ({ content, key }) => {

  const colOrder = (index) => {
    if((index + 1) % 2 == 0) {
      return 'md:order-last'
    } else {
      return '';
    }
  }

  const colSpacing = (index) => {
    if((index + 1) % 2 == 0) {
      return 'md:space-x-reverse'
    } else {
      return '';
    }
  }

  return (
    <section className=""  >
      <div className="container-v">
        {content.map((item,index) => (
          <div className="pb-[6vw]">
            <div className="anchor" id={'sec'+index} />
            {item.name &&
              <h2 className="text-lg text-center font-helvetica font-light uppercase" >{item.name}</h2>
            }
            <div className={'md:flex md:space-x-[6vw] mt-[4vw] items-center a ' + colSpacing(index)} >
              <div className={'md:w-1/2 ' + colOrder(index)}>
                {item.description &&
                  <div className="p-lrg p-styles two_col-text" dangerouslySetInnerHTML={{__html:item.description}} />
                }
              </div>
              <div className="md:w-1/2">
              {item.image &&
                <TheImage image={item.image} />
              }

              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default TwoColumnAlt
