import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "./itinerary-header.css"

export const TheImage = (props) => {
  const image = getImage(props.image.localFile)
  return (<GatsbyImage image={image} alt={'Gnar'} className="md:w-[50vw]" />)
}

const ItineraryHeader = ({ title, shortDescription, image, destinations, slug }) => {
  console.log('destinations', destinations)
  return (
    <section className="py-[10vw]">
      <div className="container-v">
        <h1 className="text-center"><span className="text-xl font-light">{title}</span></h1>
        <div className={'md:flex md:space-x-[6vw] pt-[6vw]'} >
          <div className={'md:w-1/2'}>
            {shortDescription &&
              <div className="p-lrg p-styles" dangerouslySetInnerHTML={{__html:shortDescription}} />
            }

            {destinations &&
              <div className='flex flex-wrap'>
                {destinations.map((item, index) => (
                  <>
                    <a href={'/itinerary/'+slug+'/#sec'+index} className="text-dark-blue dest-link">{item.name}</a> <span className="sep-line">|</span>
                  </>
                ))}
              </div>
            }

          </div>
          <div className="mt-[10vw] md:mt-0 md:w-1/2">
            <TheImage image={image} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ItineraryHeader
